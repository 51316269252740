import React from 'react';
import { Link, Route, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {openAuthModal} from '../../../stores/actions/auth-action'
const StepByStep = (props) => {
    const dispatch = useDispatch();

    return (
        <section className="step_sec animation-element">
            <div className="container">
                <div className="tophead">
                    <h2 className="title1 simple-stap-titl">
                        Simple Steps to come onboard  <span className="simple-stap-spn">in the carryon.bike platform</span>
                    </h2> 
                    <p>Compare and choose the BEST place to rent a bike for your specific need</p>
                </div>
                <div className="stepanel">
                    <div className="stepdiv">
                        <div>
                            <Link to="#" onClick={() => dispatch(openAuthModal('resgistration'))}>
                            <span className="ico"> 
                                <img src={require('../../../assets/images/stepico1.png')} alt=""/>
                            </span>
                            <h3>Register </h3>
                            <p>Register Yourself as Individual or business</p> 
                            </Link>
                        </div>
                        <span className="no">1</span>
                    </div>
                    <div className="stepdiv">
                        <div>
                             <span className="ico"> 
                                <img src={require('../../../assets/images/stepico2.png')} alt=""/>
                            </span>
                            <h3>Refine your profile</h3>
                            <p>Enhance details so you are easily discovered in searches</p> 
                        </div>
                        <span className="no">2</span>
                    </div>
                    <div className="stepdiv">
                        <div>
                             <span className="ico"> 
                                <img src={require('../../../assets/images/stepico3.png')} alt=""/>
                            </span>
                            <h3>Fill your post listing</h3>
                            <p>Define posts to tell the community why they should choose you</p> 
                        </div>
                        <span className="no">3</span>
                    </div>
                    <div className="stepdiv">
                        <div>
                             <span className="ico"> 
                                <img src={require('../../../assets/images/stepico4.png')} alt=""/>
                            </span>
                            <h3>Publish and shares </h3>
                            <p>Review, publish, share post listings for Glocal Bike Community </p> 
                        </div>
                        <span className="no">4</span>
                    </div>
                    <div className="stepdiv">
                        <div>
                             <span className="ico"> 
                                <img src={require('../../../assets/images/stepico5.png')} alt=""/>
                            </span>
                            <h3>Get Insightful reports</h3>
                            <p>Make data driven decisions with insightful reports</p> 
                        </div>
                        <span className="no">5</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StepByStep;
